import React from 'react';
import HeroBanner from '../components/common/headsections/HeroBanner';
import pencil from './../assets/images/icons/pencil.png';
import handshake from './../assets/images/icons/hand-shake.png';
import checkmark from './../assets/images/icons/checkmark.png';

const Dashboard: React.FC = () => {
    return (
        <div className="font-sans">
            <HeroBanner padding={'py-48'} />
        </div>
    );
};

export default Dashboard;
