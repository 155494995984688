'use client';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { RootState } from '../../store';
import { logout } from '../../features/auth/authSlice';
import logo from '../../assets/images/logo.png';

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const { user, token } = useSelector((state: RootState) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <header className="bg-white shadow-md">
      <nav aria-label="Global" className="mx-auto flex container items-center justify-between p-4 lg:px-8">
        <div className="flex">
          <a href="/" className="-m-1.5 p-1.5 text-xl font-bold">
            <img src={logo} />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>

        <div className="hidden lg:flex lg:justify-end items-center">
          <a href="/jobs" className="text-md medium mr-10">
            Available jobs
          </a>

          <a href="/companies" className="text-md medium mr-20">
            Registered companies
          </a>

          {token && user ? (
            <>
              {
                user.userType === "normal" ? (
                  <a href="/profile" className="text-md font-bold shadow-md py-2 px-14 rounded mr-3">
                    Profile
                  </a>
                ) : (
                  <a href="/business-profile" className="text-md font-bold shadow-md py-2 px-14 rounded mr-3">
                    Business Profile
                  </a>
                )
              }
              <button
                onClick={handleLogout}
                className="text-md font-bold shadow-md py-2 px-14 rounded"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <a href="/sign-in" className="text-md font-bold shadow-md py-2 px-14 rounded mr-3">
                Sign in
              </a>
              <a href="/sign-up" className="text-md font-bold shadow-md py-2 px-14 rounded">
                Sign up
              </a>
            </>
          )}
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5 text-xl font-bold">
              Damoa
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="py-6">
                {token && user ? (
                  <>
                    {
                      user.userType === "normal" ? (
                        <a
                          href="/profile"
                          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Profile
                        </a>
                      ) : (
                        <a
                          href="/business-profile"
                          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Business Profile
                        </a>
                      )
                    }

                    <button
                      onClick={handleLogout}
                      className="-mx-3 block w-full text-left rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <a
                      href="/sign-in"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign in
                    </a>
                    <a
                      href="/sign-up"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign up
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
