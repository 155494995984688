import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyDetail } from '../../features/companies/companyDetailSlice';
import { RootState, AppDispatch } from '../../store';
import companyImg from './../../assets/images/company.png';
import ReviewList from '../../components/reviews/ReviewList';
import ProjectList from '../../components/projects/ProjectList';
import LightboxGallery from '../../components/common/LightboxGallery';

const Company: React.FC = () => {
    const { username } = useParams<{ username: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const { companyDetail, status, error } = useSelector((state: RootState) => state.companyDetail);

    useEffect(() => {
        if (username) dispatch(fetchCompanyDetail(username));
    }, [username, dispatch]);

    if (error) return <p className="text-red-600">{error}</p>;
    if (!companyDetail) return <p>No company details found</p>;

    return (
        <div className="font-sans">
            <div className="container mx-auto px-8 lg:px-18 py-10">
                {status === 'loading' && 'Loading...'}
                <div className="grid grid-cols-12 gap-8">
                    <div className="col-span-4 flex flex-col">
                        <div className="flex-grow flex items-center justify-center">
                            {
                                (companyDetail.businessDetails && companyDetail.businessDetails.images) ? (
                                    <LightboxGallery images={companyDetail.businessDetails.images} />
                                ) : (
                                    <><img className="w-full rounded-2xl object-cover h-full" src={companyImg} alt="Company" /></>
                                )
                            }

                        </div>
                    </div>
                    <div className="col-span-8 flex">
                        <div className="shadow-md w-full p-8 rounded-2xl flex flex-col justify-between relative h-full">
                            <div>
                                <h4 className="font-semibold mb-3">
                                    Company Name: <span className="font-normal">{companyDetail.businessDetails.legalBusinessName || username}</span>
                                </h4>
                                <h4 className="font-semibold mb-3">
                                    Rating: <span className="font-normal">{companyDetail.rating ? `${companyDetail.rating} / 5.0` : 'No ratings yet'}</span>
                                </h4>
                                <h4 className="font-semibold mb-3">
                                    Location: <span className="font-normal">{companyDetail.businessDetails.businessLocation || 'N/A'}</span>
                                </h4>
                                <h4 className="font-semibold mb-3">
                                    Contact Details: <span className="font-normal">{companyDetail.contactNumber}</span>
                                </h4>
                                <h4 className="font-semibold mb-3">
                                    Business Fields: <span className="font-normal">{companyDetail.businessDetails.businessFields || 'N/A'}</span>
                                </h4>
                                <h4 className="font-semibold">
                                    NZBN: <span className="font-normal">{companyDetail.businessDetails.nzbn || 'N/A'}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-16"></div>
                <section id="current-projects">
                    <h3 className="font-bold text-xl text-center mb-6">Current Projects</h3>
                    <ProjectList username={companyDetail.username} />
                </section>

                <div className="mt-16"></div>
                <section id="company-reviews">
                    <h3 className="font-bold text-xl text-center mb-6">Company Review</h3>
                    <ReviewList userId={companyDetail.id} />
                </section>
            </div>
        </div>
    );
};

export default Company;
