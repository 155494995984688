import React from 'react';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import banner from '../../../assets/images/home/banner.png';

const heroBannerStyle: CSSProperties = {
  position: 'relative' as const,
  backgroundImage: `url(${banner})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
};

const overlayStyle: CSSProperties = {
  position: 'absolute' as const,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1,
};

const contentStyle: CSSProperties = {
  position: 'relative' as const,
  zIndex: 2,
};

interface Props {
  padding?: string;
}

const HeroBanner: React.FC<Props> = ({ padding = 'py-36' }) => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);

  const shouldShowPostJobButton = !user || user.userType === 'normal';

  return (
    <section id="hero-banner" className="bg-gray-800" style={heroBannerStyle}>
      <div style={overlayStyle}></div>
      <div className="container mx-auto px-4 lg:px-8" style={contentStyle}>
        <div className={padding}>
          <h3 className="font-bold text-white text-6xl">
            We Find. We Clean.We Repair.
          </h3>
          <div className="mt-10">
            <p className="font-bold text-white text-sm">What would you like to do today?</p>
          </div>
          <div className="button-group mt-5 space-x-5">
            {shouldShowPostJobButton && (
              <button
                onClick={() => navigate('/post-job')}
                className="font-bold text-black bg-white border-2 border-white text-sm min-w-60 rounded-lg py-5 hover:bg-transparent hover:text-white"
              >
                Post a cleaning job
              </button>
            )}
            {
              !user && (
                <button
                  onClick={() => navigate('/business-register')}
                  className="font-bold text-white bg-transparent border-2 border-white text-sm min-w-60 rounded-lg py-5 hover:bg-white hover:text-black"
                >
                  Business registration
                </button>
              )
            }

          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
