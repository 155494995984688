import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-20">
      <div className="container mx-auto text-center space-y-12">
        <nav className="flex flex-col md:flex-row justify-center space-x-20 text-sm font-medium">
          <a href="#" className="hover:text-gray-400 font-bold">Home</a>
          <a href="#" className="hover:text-gray-400 font-bold">About</a>
          <a href="#" className="hover:text-gray-400 font-bold">Contact</a>
          <a href="#" className="hover:text-gray-400 font-bold">Registered Company</a>
          <a href="#" className="hover:text-gray-400 font-bold">Terms and Conditions</a>
          <a href="#" className="hover:text-gray-400 font-bold">Privacy Policy</a>
        </nav>
        <p className="text-xs mt-2 font-bold">© 4 My House or its affiliates 2017</p>
      </div>
    </footer>
  );
};

export default Footer;
